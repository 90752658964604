import { BaseAuthClient } from '@simplifyd-systems/lib.client.core'

export default class PaydBaseAuthClientWithApi extends BaseAuthClient {
  constructor (options = {}) {
    const domain = process.env.VUE_APP_PAYD_API_DOMAIN
    const config = {
      baseURL: domain,
      ...options,
    }
    const token = localStorage.getItem('jwt')
    if (!token) throw new Error('Token must be set')
    super(config, token)
  }
}
