import BaseAuthClientWithApi from './BaseAuth.client'

export default class UserClient extends BaseAuthClientWithApi {
  getUsers () {
    return this
      .client
      .get('/v1/users')
  }

  getUser (id) {
    return this
      .client
      .get(`/v1/users/${id}`)
  }

  getUserLogs (id) {
    return this
      .client
      .get(`/v1/users/${id}/logs`)
  }

  getUserReferrals (id) {
    return this
      .client
      .get(`/v1/users/${id}/referrals`)
  }

  getUserSubscriptions (id) {
    return this
      .client
      .get(`/v1/users/${id}/subscriptions`)
  }

  getUserWalletTxns (id) {
    return this
      .client
      .get(`/v1/users/${id}/wallet/txns`)
  }

  getUserWalletTxn (id, txnId) {
    return this
      .client
      .get(`/v1/users/${id}/wallet/txns/${txnId}`)
  }

  getUserErrorLogs (id) {
    return this
      .client
      .get(`/v1/users/${id}/errorlogs`)
  }

  getUserWebsites (id) {
    return this
      .client
      .get(`/v1/users/${id}/zerodata/websites`)
  }

  getUserConfig (id) {
    return this
      .client
      .get(`/v1/users/${id}/config`)
  }

  getUserWalletConfig (id) {
    return this
      .client
      .get(`/v1/users/${id}/config/ngnwallet`)
  }

  sendPushNotification (id, params) {
    return this
      .client
      .post(`/v1/users/${id}/pushnotifications`, {}, { params })
  }

  getUserOTPByPhoneNumber (number) {
    return this
      .client
      .get(`/v1/random/otp/${number}`)
  }

  updateUserConfig (id, config) {
    return this
      .client
      .put(`/v1/users/${id}/config`, config)
  }

  updateUserWalletConfig (id, config) {
    return this
      .client
      .put(`/v1/users/${id}/config/ngnwallet`, config)
  }

  deleteUserByPhoneNumber (number) {
    return this
      .client
      .delete(`/v1/random/account/${number}`)
  }

  updateUserConnection (id, params) {
    return this
      .client
      .post(`/v1/users/${id}/connections`, {}, { params })
  }

  search ({ query }) {
    return this
      .client
      .get(`/v1/users/search?q=${query}`)
  }
}
