import P2pTransactionClient from '../clients/P2pTransaction.client'
import TransactionClient from '../clients/Transaction.client'
import CallClient from '../clients/Call.client'

/** mutation types */
export const set_transactions = 'set_transactions'
export const set_p2p_transactions = 'set_p2p_transactions'
export const set_calls = 'set_calls'

/** state */
const getDefaultState = () => {
  return {
    transactions: null,
    p2p_transactions: null,
    calls: null,
  }
}
const state = getDefaultState()

/** getters */
const getters = {
  transactions: (state) => state.transactions,
  p2pTransactions: (state) => state.p2p_transactions,
  calls: (state) => state.calls,
}

/** mutations */
const mutations = {
  [set_transactions] (state, data) {
    state.transactions = data
  },
  [set_p2p_transactions] (state, data) {
    state.p2p_transactions = data
  },
  [set_calls] (state, data) {
    state.calls = data
  },
}

/** actions */
const actions = {
  getTransactions: ({ commit }) => {
    return new TransactionClient()
      .getTransactions()
      .then((response) => {
        commit(set_transactions, response.data)
        return Promise.resolve(response.data)
      })
  },
  getP2pTransactions: ({ commit }) => {
    return new P2pTransactionClient()
      .getTransactions()
      .then((response) => {
        commit(set_p2p_transactions, response.data)
        return Promise.resolve(response.data)
      })
  },
  getCalls: ({ commit }) => {
    return new CallClient()
      .getCalls()
      .then((response) => {
        commit(set_calls, response.data)
        return Promise.resolve(response.data)
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
