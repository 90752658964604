import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth.store'
import user from './user.store'
import analytic from './analytic.store'
import mail from './mail.store'
import referral from './referral.store'
import training from './training.store'
import transaction from './transaction.store'
import ui from './ui.store'

Vue.use(Vuex)

const store = new Vuex.Store({
  namespaced: true,
  modules: {
    ui,
    auth,
    user,
    analytic,
    mail,
    referral,
    training,
    txns: transaction,
  },
})

export default store
