import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const guardProtectedRoutes = (_, __, next) => {
  let path;
  if (!localStorage.getItem("jwt")) {
    /* go to login */
    path = { path: "/login" };
  }
  next(path);
};
const guardAuthRoutes = (_, __, next) => {
  let path;
  if (localStorage.getItem("jwt")) {
    /* go to app */
    path = { path: "/dashboard" };
  }
  next(path);
};

const routes = [
  {
    path: "/",
    component: () => import("@/views/auth/AuthLayout.vue"),
    beforeEnter: guardAuthRoutes,
    children: [
      {
        name: "login",
        path: "/login",
        component: () => import("@/views/auth/Login.vue"),
      },
      {
        path: "/",
        redirect: { name: "login" },
      },
    ],
  },
  {
    name: "auth",
    path: "/auth",
    component: () => import("@/views/auth/AuthLayout.vue"),
    children: [
      {
        path: "callback",
        name: "callback",
        component: () => import("@/views/auth/Callback.vue"),
        props: (route) => ({ token: route.query.token }),
      },
    ],
  },
  /** app, guarded routes */
  {
    path: "/",
    component: () => import("@/views/AppLayout.vue"),
    beforeEnter: guardProtectedRoutes,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          title: "Dashboard",
        },
      },
      {
        path: "/dashboard/active-connections",
        name: "activeConnections",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          title: "Dashboard",
          modalTitle: "Connected in Last 30mins",
          showModal: true,
          showOnlineModal: false,
        },
      },
      {
        path: "/dashboard/online-connections",
        name: "onlineConnections",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          title: "Dashboard",
          modalTitle: "Online Users",
          showModal: false,
          showOnlineModal: true,
        },
      },
      {
        path: "/dashboard/search-user/:id",
        name: "searchUser",
        component: () => import("@/views/dashboard/SearchUser.vue"),
        props: (route) => ({
          id: route.params.id,
          mobile: route.params.mobile,
        }),
        meta: {
          title: "User logs",
          hasBackButton: true,
        },
      },
      /** debugger */
      {
        path: "/debug",
        name: "debug",
        component: () => import("@/views/Debug.vue"),
        meta: {
          title: "Debug",
        },
      },
      {
        path: "/deliverability",
        name: "deliverability",
        component: () => import("@/views/Deliverability.vue"),
        meta: {
          title: "Message Deliverability",
        },
      },
      /** training data */
      {
        path: "/training",
        name: "training",
        component: () => import("@/views/training/Training.vue"),
        meta: {
          title: "Training Data",
          hasActionButton: true,
          actionButton: {
            title: "Create Training Data",
            linkName: "createTrainingData",
            icon: "mdi-plus",
          },
        },
      },
      {
        path: "/training/create",
        name: "createTrainingData",
        component: () => import("@/views/training/Training.vue"),
        meta: {
          title: "Training Data",
          showCreateModal: true,
        },
      },
      /** transactions */
      {
        path: "/transactions",
        name: "transactions",
        component: () => import("@/views/transaction/Transactions.vue"),
        meta: {
          title: "Transactions",
          hasBackButton: true,
        },
      },
      {
        path: "/p2p-transactions",
        name: "p2pTransactions",
        component: () => import("@/views/transaction/P2pTransactions.vue"),
        meta: {
          title: "P2P Transactions",
          hasBackButton: true,
        },
      },
      {
        path: "/calls",
        name: "callActivity",
        component: () => import("@/views/transaction/Calls.vue"),
        meta: {
          title: "Call Activity",
          hasBackButton: true,
        },
      },
      /** apps */
      {
        path: "/users",
        redirect: { name: "users" },
      },
      {
        path: "/users/all",
        name: "users",
        component: () => import("@/views/apps/Apps.vue"),
        meta: {
          title: "Users",
        },
      },
      {
        path: "/users/subscriptions",
        name: "subscriptions",
        component: () => import("@/views/apps/Subscriptions.vue"),
        meta: {
          title: "Subscriptions",
          hasBackButton: true,
        },
      },
      /** referrals */
      {
        path: "/users/referrals",
        name: "referral",
        component: () => import("@/views/apps/Referrals.vue"),
        meta: {
          title: "Referrals",
          hasBackButton: true,
        },
      },
      {
        path: "/users/:id",
        component: () => import("@/views/apps/App.vue"),
        props: (route) => ({
          id: route.params.id,
        }),
        meta: {
          hasBackButton: true,
        },
        children: [
          {
            path: "errors",
            name: "appErrorLogs",
            component: () => import("@/views/apps/AppErrorLogs.vue"),
            props: (route) => ({
              id: route.params.id,
            }),
            meta: {
              hasBackButton: true,
            },
          },
          {
            path: "logs",
            name: "appLogs",
            component: () => import("@/views/apps/AppLogs.vue"),
            props: (route) => ({
              id: route.params.id,
            }),
            meta: {
              hasBackButton: true,
            },
          },
          {
            path: "referrals",
            name: "appReferrals",
            component: () => import("@/views/apps/AppReferrals.vue"),
            props: (route) => ({
              id: route.params.id,
            }),
            meta: {
              hasBackButton: true,
            },
          },
          {
            path: "subscriptions",
            name: "appSubscriptions",
            component: () => import("@/views/apps/AppSubscriptions.vue"),
            props: (route) => ({
              id: route.params.id,
            }),
            meta: {
              hasBackButton: true,
            },
          },
          {
            path: "subscriptions/:subId",
            name: "appSubscriptionDetail",
            component: () => import("@/views/apps/AppSubscriptions.vue"),
            props: (route) => ({
              id: route.params.id,
              subId: route.params.subId,
            }),
            meta: {
              showModal: true,
              hasBackButton: true,
            },
          },
          {
            path: "transactions",
            name: "appWalletTxn",
            component: () => import("@/views/apps/AppWalletTxn.vue"),
            props: (route) => ({
              id: route.params.id,
            }),
            meta: {
              hasBackButton: true,
            },
          },
          {
            path: "transactions/:txnId",
            name: "appWalletTxnDetail",
            component: () => import("@/views/apps/AppWalletTxn.vue"),
            props: (route) => ({
              id: route.params.id,
              txnId: route.params.txnId,
            }),
            meta: {
              showModal: true,
              hasBackButton: true,
            },
          },
          {
            path: "websites",
            name: "appWebsites",
            component: () => import("@/views/apps/AppWebsites.vue"),
            props: (route) => ({
              id: route.params.id,
            }),
            meta: {
              hasBackButton: true,
            },
          },
          {
            path: "details",
            name: "appDetails",
            component: () => import("@/views/apps/AppDetails.vue"),
            props: (route) => ({
              id: route.params.id,
            }),
            meta: {
              hasBackButton: true,
            },
          },
          {
            path: "",
            redirect: { name: "appDetails" },
          },
        ],
      },
      /** catch-all */
      {
        path: "/*",
        redirect: { name: "dashboard" },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

export default router;
